(function() {
  'use strict';
  angular
    .module('app')
    .run(actionCable)
    .constant('BASE_URL', getConstantBySubDomain())
    .constant('DOMAIN_URL', getConstantBySubDomain())
    .run(run);

  actionCable.$inject = ['ActionCableConfig','BASE_URL']
  run.$inject = ['$rootScope', '$state', 'authService', '$document', '$transitions', 'user'];

  function getConstantBySubDomain(){
    var subdomain = window.location.href.split('.')[0].split('//')[1]

    // return 'http://dataweb.lvh.me:4007'
    return 'https://dataweb.cdapuestas.com';
  }

  function actionCable(ActionCableConfig,BASE_URL){
    if(BASE_URL.split('/')[2].split(':')[0] == 'localhost'){
      ActionCableConfig.wsUri = "ws://"+BASE_URL.split('/')[2]+"/cable";
    }else{
      ActionCableConfig.wsUri = "wss://"+BASE_URL.split('/')[2]+"/cable";
    }
    ActionCableConfig.autoStart = true;
  }

  function run($rootScope, $state, $authService, $document, $transitions, $user) {
    $transitions.onSuccess({ }, function() {
      $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
    });

    $transitions.onError({ }, function(trans) {
      switch (trans.error().detail) {
        case 'ALREADY_LOGGED_IN':
          $state.go('home');
          break;
        case 'UNAUTHORIZED':
        //console.log('UNAUTHORIZED')
          unauthorizedHandler();
          break;
        default:
          $state.go('404');
      }
    });

    $rootScope.$on('UNAUTHORIZED', unauthorizedHandler);

    function unauthorizedHandler() {
      if($authService.logout()){
        $state.go('login');
      }
    }
  }
})();
